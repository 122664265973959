import React, { useState, useEffect, useContext } from 'react'
import { ConfigProvider, message } from 'antd'
import localeCs from 'antd/lib/locale/cs_CZ'
import localeEn from 'antd/lib/locale/en_GB'
import { useNavigate } from 'react-router-dom'

import I18nContext from 'contexts/i18n-context'
import { AuthContext } from 'contexts/auth-context'
import { ActionCableProvider } from 'contexts/action-cable-context'
import { AbilityContext } from 'contexts/ability-context'

import { ApiClient, ApiProvider } from 'jsonapi-react'

import Routes from 'configs/routes'
import schema from 'configs/jsonapi-schema.json'
// import ability from 'configs/ability'
import { buildAbilityFor } from 'configs/ability'

import './app.less'
import 'leaflet/dist/leaflet.css'

import 'dayjs/locale/cs'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('cs')

const App = () => {
  let auth = useContext(AuthContext)
  let navigate = useNavigate()
  const i18n = useContext(I18nContext)
  
  const [ability, setAbility] = useState(undefined)

  const client = new ApiClient({
    url: `${process.env.REACT_APP_API_HOST}/api/v1`,
    headers: {
      Authorization: auth.token,
      Locale: i18n.locale,
    },
    schema,
  })

  useEffect(() => {
    client.onError((action) => {
      if (action.error === 'Unauthorized') {
        auth.forceSignOut(() => {
          message.error(i18n.t('devise.failure.timeout'))
          navigate('/user/login')
        })
      }
      if (action.error === 'Forbidden') {
        message.error('Forbidden')
      }
    })
  }, [client])
  
  useEffect(() => {
    setAbility(buildAbilityFor(auth))
  }, [auth.user])

  return (
    <ConfigProvider locale={i18n.locale === 'cs' ? localeCs : localeEn} theme={{
      token: {
        colorPrimary: '#1DA57A',
        colorLink: '#1DA57A',
        colorLinkActive: "#118060",
        colorLinkHover: '#3db389'
      },
    }}>
      <ApiProvider client={client}>
        <AbilityContext.Provider value={ability}>
          <ActionCableProvider>
            <Routes />
          </ActionCableProvider>
        </AbilityContext.Provider>
      </ApiProvider>
    </ConfigProvider>
  )
}

export default App
