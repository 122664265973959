import React, { useContext } from 'react'
import { Select } from 'antd'

import I18nContext from 'contexts/i18n-context'
import Locales from 'components/locales'

const LocaleSelect = (props) => {
  const i18n = useContext(I18nContext)

  return (
    <Select {...props}>
      {Object.values(Locales).map((locale) => (
        <Select.Option key={locale.lang} value={locale.lang}>
          {locale.icon} {i18n.t(locale.title)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default LocaleSelect
