import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './app';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { AuthContextProvider } from 'contexts/auth-context'
import { I18nProvider } from 'contexts/i18n-context'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <I18nProvider>
    <AuthContextProvider>
      <Router>
        <App />
      </Router>
    </AuthContextProvider>
  </I18nProvider>
);