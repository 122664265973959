import React, { useState, useEffect, useContext, Suspense } from 'react'
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom'
import I18nContext from 'contexts/i18n-context'
import { AuthContext } from 'contexts/auth-context'
// import { AbilityContext } from 'contexts/ability-context'

import TopHeaderLeft from './components/top-header-left'
import TopHeaderRight from './components/top-header-right'
import RailsFooter from './components/rails-footer'
import AreaName from 'components/area-name'

import { Spin } from 'antd'

import { Can } from 'contexts/ability-context'

// import ProLayout, { SettingDrawer, PageLoading } from '@ant-design/pro-layout'
import { ProLayout, ProConfigProvider, SettingDrawer } from '@ant-design/pro-components'
// import { ProConfigProvider } from '@ant-design/pro-provider'

import { SettingOutlined, UserOutlined, DashboardOutlined, HistoryOutlined, NodeIndexOutlined, MailOutlined } from '@ant-design/icons'

import { useQuery } from 'jsonapi-react'

import logo from './assets/logo.svg'

const RequireAuth = ({ children }) => {
  let auth = useContext(AuthContext)
  let location = useLocation()
  const i18n = useContext(I18nContext)
  // const ability = useContext(AbilityContext)
  
  const [currentUserId, setCurrentUserId] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  
  useEffect(() => {
    setCurrentUserId(auth?.user?.id)
  }, [auth.user])
  
  const { data: user, isLoading: userIsLoading } = useQuery(currentUserId && [
    'users', currentUserId,
    { 
      include: 'areas,areas.project',
      fields: {
        areas: 'name,project',
        projects: 'name,logo_meta'
      }
    }
  ])
  
  // const [settings, setSettings] = useState({ /*colorPrimary: "#1DA57A", fixSiderbar: true, layout: 'mix', splitMenus: true,*/ /*layout: 'mix'*/ /*fixedHeader: true, fixSiderbar: true,*/ /*splitMenus: true*/ })
  const [settings, setSettings] = useState({
    colorPrimary: "#1DA57A",
    fixSiderbar: true,
    layout: "mix",
    splitMenus: false,
    navTheme: "light",
    contentWidth: "Fluid",
    siderMenuType: "sub"
  })
  const [selectedKeys, setSelectedKeys] = useState([])
  
  const menuItems = [
    {
      key: 'dashboard',
      path: '/dashboard',
      name: 'Dashboard',
      icon: <DashboardOutlined />
    },
    {
      key: 'events',
      path: '/events',
      name: i18n.t('events.index.title'),
      icon: <HistoryOutlined />,
      isSelected: false,
    },
    {
      key: 'events_replay',
      path: '/events-replay',
      name: i18n.t('events_replay.index.title'),
      icon: <NodeIndexOutlined />,
      isSelected: false,
    },
    {
      key: 'admin',
      path: '/admin',
      name: 'Admin',
      icon: <SettingOutlined />,
      isSelected: false,
      hideInMenu: !(auth?.user?.role_ids?.includes(1) || auth?.user?.role_ids?.includes(3)),
      routes: [
        {
          key: 'admin_users',
          path: '/admin/users',
          name: i18n.t('users.index.title'),
          icon: <UserOutlined />,
          isSelected: false,
          items: []
        },
        {
          key: 'admin_projects',
          path: '/admin/projects',
          name: i18n.t('projects.index.title'),
          icon: '',
          isSelected: false,
          items: []
        },
        {
          key: 'admin_areas',
          path: '/admin/areas',
          name: i18n.t('areas.index.title'),
          icon: <UserOutlined />,
          isSelected: false,
        },
        {
          key: 'admin_area',
          path: '/admin/areas/:id',
          name: i18n.t('activerecord.models.area'),
          icon: <UserOutlined />,
          isSelected: false,
          hideInMenu: true,
          routes: [
            {
              path: '/admin/areas/:id/maps',
              name: i18n.t('maps.index.title')
            },
            {
              path: '/admin/areas/:id/bluetooth-devices',
              name: i18n.t('bluetooth_devices.index.title')
            },
            {
              path: '/admin/areas/:id/nfcs',
              name: i18n.t('nfcs.index.title')
            }
          ]
        },
        {
          key: 'admin_configurations',
          path: '/admin/configurations',
          name: i18n.t('configurations.index.title'),
          icon: <UserOutlined />,
          isSelected: false,
          items: []
        },
        {
          key: 'admin_unassigned_mobile_devices',
          path: '/admin/unassigned-mobile-devices',
          name: i18n.t('unassigned_mobile_devices.index.title'),
          icon: <UserOutlined />,
          isSelected: false,
          hideInMenu: !(auth?.user?.role_ids?.includes(1)),
          items: []
        },
        {
          key: 'admin_mobile_devices',
          path: '/admin/mobile-devices',
          name: i18n.t('mobile_devices.index.title'),
          icon: <UserOutlined />,
          isSelected: false,
          items: []
        },
        {
          key: 'admin_emails',
          path: '/admin/emails',
          name: i18n.t('emails.index.title'),
          icon: <MailOutlined />,
          isSelected: false,
          hideInMenu: !(auth?.user?.role_ids?.includes(1)),
          items: []
        }
      ]
    }
  ]

  useEffect(() => {
    setSelectedKeys([location.pathname.replace(/[/-]/g, '_').substring(1)])
  }, [location])
  
  if (!(auth && auth.user)) {
    return <Navigate to="/user/login" state={{ from: location }} replace />;
  }
  
  // console.log(settings)
  
  // https://procomponents.ant.design/en-US/components/layout
  // https://github.com/ant-design/pro-components/blob/master/packages/layout/src/demos/dark.tsx
  // https://ant.design/docs/react/customize-theme
  
  // https://github.com/ant-design/ant-design-pro/issues/10618
  // https://github.com/ant-design/ant-design-pro/issues/10651

  // headerTitleRender={(logo,title,props) => }
  
  // siderMenuType="group"
  // menu={{
  //   collapsedShowGroupTitle: true,
  // }}
  
  // token={{
  //   header: {
  //     colorBgMenuItemSelected: 'rgba(0,0,0,0.04)',
  //   },
  // }}
  
  /*menuDataRender={() => menuItems}*/

  return (
    <>
      { /* auth.loading && <PageLoading /> */ }
      { /* !collapse && auth?.area?.project?.logo_meta && location.pathname === '/dashboard' ? <img width={180} style={{ width: '208px', padding: '10px', position: 'absolute', left: 0, bottom: '3%', zIndex: 9999 }} src={`${process.env.REACT_APP_API_HOST}${auth?.area?.project?.logo_meta?.thumb_url}`} /> : null */ }
      <div
        id="main-pro-layout"
        style={{
          height: '100vh',
          overflow: 'auto',
          minHeight: 800
        }}
      >
        <ProConfigProvider hashed={false}>
          <ProLayout
            {...settings}
            token={{
              bgLayout: '#f0f2f5',
              header: {
                colorBgHeader: '#fff',
                colorHeaderTitle: '#fff'
              },
              sider: {
                colorBgCollapsedButton: '#fff',
                colorTextCollapsedButtonHover: 'rgba(0,0,0,0.65)',
                colorTextCollapsedButton: 'rgba(0,0,0,0.45)',
                colorMenuBackground: '#001529',
                colorBgMenuItemCollapsedElevated: 'rgba(0,0,0,0.85)',
                colorMenuItemDivider: 'rgba(255,255,255,0.15)',
                colorBgMenuItemHover: 'rgba(0,0,0,0.06)',
                colorBgMenuItemSelected: '#1da57a',
                colorTextMenuSelected: '#fff',
                colorTextMenuItemHover: 'rgba(255,255,255,0.75)',
                colorTextMenu: 'rgba(255,255,255,0.75)',
                colorTextMenuSecondary: 'rgba(255,255,255,0.65)',
                colorTextMenuTitle: 'rgba(255,255,255,0.95)',
                colorTextMenuActive: 'rgba(255,255,255,0.95)',
                colorTextSubMenuSelected: '#fff',
              },
            }}
            navTheme={'dark'}
            headerTheme={'dark'}
            heme="dark"
            fixedHeader={true}
            fixSiderbar={true}
            headerTitleRender={(logo, title, props) => {
              const defaultDom = (
                <div style={{ margin: `0 0 0 ${collapsed === false && 16 || 15}px`, width: '49px', ...(collapsed === false && { width: `${props.siderWidth - 16}px` }) }}>
                  <a>
                    {logo}
                    {collapsed === false && title}
                  </a>
                </div>
              )
              if (typeof window === 'undefined') return defaultDom
              if (document.body.clientWidth < 1400) {
                return defaultDom
              }
              if (props.isMobile) return defaultDom
              return (
                <>
                  {defaultDom}
                </>
              )
            }}
            menuFooterRender={(props) => {
              if (props?.collapsed) return undefined;
              return (
                <div
                  style={{
                    textAlign: 'center',
                    padding: 12,
                  }}
                >
                  {auth?.area?.project?.logo_meta && location.pathname === '/dashboard' ? <img width={'100%'} src={`${process.env.REACT_APP_API_HOST}${auth?.area?.project?.logo_meta?.thumb_url}`} /> : null}
                </div>
              );
            }}
            loading={currentUserId === null || userIsLoading}
            title="ManDown"
            logo={logo}
            itemRender={(currentPath, breadcrumbName, paths) => {
              const isLastItem = paths.findIndex(object => object.path === currentPath.path) === paths.length - 1;

              return isLastItem || currentPath.path === null ? <span>{currentPath.breadcrumbName}</span> : <Link to={currentPath.path}>{currentPath.breadcrumbName}</Link>
            }}
            menuDataRender={() => menuItems}
            menuItemRender={(menuItemProps, defaultDom) => {
              return <Link to={menuItemProps.path}>{defaultDom}</Link>;
            }}
            selectedKeys={selectedKeys}
            headerContentRender={(props) => <TopHeaderLeft user={user} userIsLoading={currentUserId === null || userIsLoading} /> }
            rightContentRender={rightProps => <TopHeaderRight {...rightProps} />}
            footerRender={() => <RailsFooter />}
            onCollapse={(state) => setCollapsed(state)}
            breadcrumbRender={(route) => (
              route.map((item) => {
                const areaPath = item.linkPath.match(/\/admin\/areas\/(\d+)$/)
            
                if (areaPath) {
                  item.path = null
                  item.breadcrumbName = <AreaName id={areaPath.at(1)} />
                }
            
                return item
              })
            )}
          >
            <Suspense fallback={<div style={{ paddingBlockStart: 100, textAlign: 'center' }}>
              <Spin size="large" />
            </div>}>
              <Outlet />
            </Suspense>
          </ProLayout>
        </ProConfigProvider>
      </div>
      {/*<SettingDrawer settings={settings} onSettingChange={setSettings} />*/}
    </>
  )
}

export default RequireAuth