import React, { createContext, useContext } from 'react'
import { createConsumer } from '@rails/actioncable'
import { AuthContext } from 'contexts/auth-context'

const ActionCableContext = createContext();

const  ActionCableProvider = ({ children }) => {
  let auth = useContext(AuthContext);
  
  const CableApp = {}
  CableApp.cable = createConsumer(`${process.env.REACT_APP_WS_URL}?token=${auth.token?.split(' ')?.[1]}`)
  
  return <ActionCableContext.Provider value={CableApp.cable}>{children}</ActionCableContext.Provider>;
};

export { ActionCableContext, ActionCableProvider };