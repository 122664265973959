import React, { createContext, useContext } from 'react'
import { I18n } from 'i18n-js'
import locales from 'configs/locales.json'

const I18nContext = createContext()

const I18nProvider = ({ children }) => {
  const i18n = new I18n(locales)
  
  i18n.defaultLocale = 'cs'
  i18n.locale = 'cs'
  
  // i18n.onChange(() => {})

  return <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>
};

export { I18nContext, I18nProvider }
export default I18nContext