import React, { useEffect, useContext } from 'react';
import { Button, Form, Input, message } from 'antd';

import styles from './split_view.module.css';

import { AuthContext } from 'contexts/auth-context'
import I18nContext from 'contexts/i18n-context'

const SecurityView = () => {
  const [form] = Form.useForm()
  
  let auth = useContext(AuthContext);
  const i18n = useContext(I18nContext)
  const options = { scope: "activerecord.attributes.user" };

  useEffect(() => {
    return () => { form.resetFields() }
  }, [])

  const handleSubmit = (event) => {
    form.validateFields().then(async (values) => {
      fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users/update_password`, {
        method: 'PATCH',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': auth.token,
          'Locale': i18n.locale
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ user: values })
      })
      .then(response => {
        if (!response.ok) { throw response }
      
        return response.json()
      })
      .then(user => {
        message.success(i18n.t(`flash.actions.update.notice`, { resource_name: i18n.t(`activerecord.attributes.user.password`) }))
      
        form.resetFields()
      })
      .catch(error => {
        error
          .json()
          .then(json => {
            let attributes = []
      
            json.errors.forEach(item => {
              attributes = [...attributes, {
                name: item.meta.attribute,
                value: form.getFieldValue(item.meta.attribute),
                errors: [item.meta.message]
              }]
            })
            form.setFields(attributes)
      
            message.error(i18n.t(`flash.actions.update.alert`, { resource_name: i18n.t(`activerecord.attributes.user.password`) }))
          })
      })
    }).catch(e => {
      message.error(i18n.t(`flash.actions.update.alert`, { resource_name: i18n.t(`activerecord.attributes.user.password`) }))
    })
  }

  return (
    <div className={styles.splitView}>
      <div className={styles.left}>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          autoComplete={'off'}
        >
          <Form.Item
            label={i18n.t('current_password', options)}
            name={'current_password'}
            rules={[
              {
                required: true,
                message: i18n.t('change_password.index.current_password_blank')
              },
            ]}
            hasFeedback
          >
            <Input.Password
              autoComplete={"new-password"}
            />
          </Form.Item>
          <Form.Item
            label={i18n.t('password', options)}
            name={'password'}
            hasFeedback
            rules={[
              {
                required: true,
                message: i18n.t('change_password.index.new_password_blank'),
              }
            ]}
          >
            <Input.Password
              autoComplete={"new-password"}
            />
          </Form.Item>
          <Form.Item
            label={i18n.t('password_confirmation', options)}
            name={'password_confirmation'}
            hasFeedback
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: i18n.t('change_password.index.new_password_confirmation_blank'),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }

                  return Promise.reject(i18n.t('change_password.index.new_password_not_match'))
                },
              }),
            ]}
          >
            <Input.Password
              autoComplete={"new-password"}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">{i18n.t('save')}</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default SecurityView;
