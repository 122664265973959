// import { BellOutlined, QuestionCircleOutlined, RollbackOutlined, SyncOutlined } from '@ant-design/icons';
// import { Tooltip, Badge } from 'antd';
import React, { useContext } from 'react';

import AvatarDropdown from './AvatarDropdown';
// import NoticeIconView from './NoticeIconView';
// import styles from './index.less';
// import * as styles from './index.less';
import './index.less';

import { AuthContext } from 'contexts/auth-context'

import LocaleDropdown from 'components/locale-dropdown'

const GlobalHeaderRight = (props) => {
  const auth = useContext(AuthContext);
  
  return (
    <div className={'right'}>
      {/*auth.user.backAdminId !== null &&
        <Tooltip
          title="Back as admin"
        >
          <a
            href={`/admin/users/${auth.user.backAdminId}/become`}
            className={'action'}
          >
            <RollbackOutlined />
          </a>
        </Tooltip>
      */}
      {/*<Tooltip
        title="Help"
      >
        <a
          target="_blank"
          href="#"
          rel="noopener noreferrer"
          className={'action'}
        >
          <QuestionCircleOutlined style={{ fontSize: '16px' }} />
        </a>
      </Tooltip>*/}
      {/*<a
        href="#"
        rel="noopener noreferrer"
        className={'action'}
      >
        <Badge count={0}>
          <BellOutlined style={{ fontSize: '16px' }} />
        </Badge>
      </a>*/}
      {/*<NoticeIconView />*/}
      <LocaleDropdown />
      <AvatarDropdown currentUser={auth.user} menu={true} />
    </div>
  );
};

export default GlobalHeaderRight