import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Form, Alert, Input, Checkbox, Button, message } from 'antd';

import { UserOutlined, LockOutlined } from '@ant-design/icons';

import logo from 'assets/logo.svg';

import styles from './style.module.less';

import { AuthContext } from 'contexts/auth-context'

import Footer from 'components/rails-footer';
import I18nContext from 'contexts/i18n-context'

import LocaleDropdown from 'components/locale-dropdown'

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useContext(AuthContext);
  const i18n = useContext(I18nContext)
  
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);

  let from = location.state?.from?.pathname || "/";

  const handleSubmit = (values) => {
    setError(null)
    setSubmitting(true);

    auth.signin(values.email, values.password, () => {
      message.success(i18n.t('devise.sessions.signed_in'));
      navigate(from, { replace: true });
      
      setSubmitting(false)
    }, (errorMessage) => {
      setError(errorMessage)
      
      setSubmitting(false)
    });
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.lang}>
        <LocaleDropdown />
      </div>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.header}>
            <Link to="/">
              <img alt="logo" className={styles.logo} src={logo} />
              <span className={styles.title}>{i18n.t('sitename')}</span>
            </Link>            
          </div>
          <div className={styles.desc}></div>
        </div>

        <div className={styles.main}>
          <Form onFinish={handleSubmit}>
            {error !== null && !submitting && (
              <Alert
                style={{
                  marginBottom: 24,
                }}
                type="error"
                showIcon
                message={error} />
            )}

            <Form.Item name="email" rules={[{ required: true, message: i18n.t('login.index.email_blank') }]}>
              <Input size="large" prefix={<UserOutlined />} placeholder={i18n.t('activerecord.attributes.user.email')} />
            </Form.Item>
            
            <Form.Item name="password" rules={[{ required: true, message: i18n.t('login.index.password_blank') }]}>
              <Input.Password size="large" prefix={<LockOutlined />} placeholder={i18n.t('activerecord.attributes.user.password')} />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{i18n.t('activerecord.attributes.user.remember')}</Checkbox>
              </Form.Item>
              <Link
                to='/user/reset-password'
                style={{
                  float: 'right',
                }}
              >
                {i18n.t('login.index.forgot_your_password')}
              </Link>
            </Form.Item>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit" loading={submitting} block>
                {i18n.t('login.index.sign_in')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LoginPage;
