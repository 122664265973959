import React, { useContext } from 'react'
import { Result, Button } from 'antd'
import { Outlet } from 'react-router-dom'

import { AuthContext } from 'contexts/auth-context'

const RequireAdmin = ({ children }) => {
  let auth = useContext(AuthContext)
  
  if (!(auth?.userIsAdmin || auth?.userIsManager)) {
    return <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={<Button type="primary">Back Home</Button>}
    />
  } else {
    return <Outlet />
  }
}

export default RequireAdmin