import React, { useEffect, useContext } from 'react';
import { Button, Form, Input, message, Skeleton } from 'antd';
import UserAvatar from 'components/user-avatar';
import LocaleSelect from 'components/locale-select'

import styles from './split_view.module.css';

import { AuthContext } from 'contexts/auth-context'
import I18nContext from 'contexts/i18n-context'

import { useMutation, useQuery } from 'jsonapi-react';

const BaseView = () => {
  const [form] = Form.useForm()
  
  let auth = useContext(AuthContext);
  const i18n = useContext(I18nContext)
  const options = { scope: "activerecord.attributes.user" };
  
  const { data, isLoading } = useQuery(['users', auth.user.id])
  const [mutate] = useMutation(['users', auth.user.id])

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({ ...data })
  
    return () => { form.resetFields() }
  }, [data])

  const handleSubmit = async (values) => {
    const result = await mutate(values)
    
    let attributes = []

    Object.entries(values).forEach(([attribute, value]) => {
      const resultError = result?.errors?.find(e => e.meta.attribute === attribute)
      
      attributes = [...attributes, {
        name: attribute,
        value: value,
        errors: resultError !== undefined ? [resultError.detail] : []
      }]
    })

    form.setFields(attributes)
    
    if (!(result.error || result.errors)) {
      message.success(i18n.t(`flash.actions.update.notice`, { resource_name: i18n.t(`activerecord.models.user`) }))
      
      auth.reloadUser(() => {})
    }
  }
  
  if (isLoading) return (
    <div className={styles.splitView}>
      <div className={styles.left}>
        <br />
        <br />
        <Skeleton.Input active={true} />
        <br />
        <br />
        <Skeleton.Input active={true} />
        <br />
        <br />
        <Skeleton.Input active={true} />
        <br />
        <br />
        <Skeleton.Button active={true} />
      </div>
      <div className={styles.right}>
        <div className={styles.avatar}>
          <Skeleton.Avatar active={true} size={140} />
        </div>
      </div>
    </div>
  )

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={handleSubmit}
    >
      <div className={styles.splitView}>
        <div className={styles.left}>
          <Form.Item
            name="first_name"
            label={i18n.t('first_name', options)}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label={i18n.t('last_name', options)}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={i18n.t('email', options)}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="locale"
            label={i18n.t('locale', options)}
          >
            <LocaleSelect />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">{i18n.t('save')}</Button>
          </Form.Item>
        </div>
        <div className={styles.right}>
          <div>Avatar</div>
          <div className={styles.avatar}>
            <UserAvatar user={auth.user} size={140} alt="avatar" />
          </div>
        </div>
      </div>
    </Form>
  )
}

export default BaseView;
