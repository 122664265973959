import { Select, Spin } from 'antd';
import React, { useEffect, useContext, useState } from 'react';

import { useQuery, useClient } from 'jsonapi-react'

import { AuthContext } from 'contexts/auth-context'
import I18nContext from 'contexts/i18n-context'

import { useLocation } from 'react-router-dom'

const GlobalHeaderLeft = (props) => {
  const { user, userIsLoading } = props
  
  let location = useLocation()
  
  const auth = useContext(AuthContext)
  const i18n = useContext(I18nContext)
  
  const [nameCont, setNameCont] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  
  const client = useClient()
  
  useEffect(() => {
    if (user?.default_area_id) {
      auth.setArea(data?.find((area) => area?.id && parseInt(area?.id) === user?.default_area_id))
    }
  }, [user])

  const changeArea = (value) => {
    auth.setArea(value === undefined ? null : user?.areas?.find((area) => parseInt(area?.id) === value))
  }
  
  const onSearch = async (value = '') => {
    setLoading(true)

    const { data, error } = await client.fetch(
      [
        'areas', { 
          include: 'project',
          fields: {
            areas: 'name,project',
            projects: 'name'
          },
          filter: {
            name_or_project_name_cont: value
          },
          page: { number: 1, size: 30 }
        }
      ]
    )

    setData(data)
    setLoading(false)
    return data
  }
  
  useEffect(() => {
    onSearch()
  }, [])
  
  return (
    <div style={{ marginLeft: '38px' }}>
      {
        location.pathname === '/dashboard' ?
          <Select
            onChange={changeArea}
            value={auth?.area && parseInt(auth.area.id)}
            style={{ width: '450px' }}
            loading={loading}
            placeholder={`${i18n.t('select')} ${i18n.t('activerecord.models.area').toLowerCase()}`}
            allowClear={true}
            onSearch={onSearch}
            showSearch={true}
            filterOption={false}
            notFoundContent={loading ? <Spin size="small" /> : null}
            options={data?.map((area) => ({ value: parseInt(area.id), label: `${area.project.name} / ${area.name}` })).sort((a, b) => a.label.localeCompare(b.label))}
          /> : null
      }
    </div>
  )
}

export default GlobalHeaderLeft