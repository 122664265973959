import React, { useState, useContext } from 'react';
import { Menu } from 'antd';
import { GridContent } from '@ant-design/pro-components';
import styles from './style.module.css';

import BaseView from './base_view';
import SecurityView from './security_view';

import I18nContext from 'contexts/i18n-context'

import { UserOutlined, LockOutlined } from '@ant-design/icons'

const { Item } = Menu;

const Tabs = () => {
  const i18n = useContext(I18nContext)
  
  const menuMap = {
    base: i18n.t('users.edit.basic_settings'),
    security: i18n.t('users.edit.security_settings')
  }
  const [selectKey, setSelectKey] = useState('base')

  const menuItems = [
    {
      label: i18n.t('users.edit.basic_settings'),
      key: 'base',
      icon: <UserOutlined />
    }, {
      label: i18n.t('users.edit.security_settings'),
      key: 'security',
      icon: <LockOutlined />
    }
  ]

  const getRightTitle = () => {
    return menuMap[selectKey]
  };

  const renderChildren = () => {
    switch (selectKey) {
      case 'base':
        return <BaseView />;
      case 'security':
        return <SecurityView />;
      // case 'notification':
      //   return <NotificationView />;
      default:
        break;
    }

    return null
  }

  return (
    <GridContent>
      <div
        className={styles.main}
      >
        <div className={styles.leftMenu}>
          <Menu
            mode={'inline'}
            selectedKeys={[selectKey]}
            onClick={({ key }) => setSelectKey(key)}
            items={menuItems}
            theme='light'
          />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>{getRightTitle()}</div>
          {renderChildren()}
        </div>
      </div>
    </GridContent>
  )
}

export default Tabs
