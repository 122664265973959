import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import { Form, Alert, Input, Button, message } from 'antd';

import { LockOutlined } from '@ant-design/icons';

import logo from 'assets/logo.svg';

import styles from 'pages/user/login/style.module.less';

import { AuthContext } from 'contexts/auth-context'

import Footer from 'components/rails-footer';
import I18nContext from 'contexts/i18n-context'

import LocaleDropdown from 'components/locale-dropdown'

function ChangePasswordPage(props) {
  let params = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useContext(AuthContext);
  const i18n = useContext(I18nContext)
  
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);

  let from = location.state?.from?.pathname || "/";
  
  // console.log(params)

  const handleSubmit = async (values) => {
    setError(null)
    setSubmitting(true);

    const response = await fetch(`${process.env.REACT_APP_API_HOST}/users/password`, {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Locale': i18n.locale
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        user: {
          reset_password_token: params.reset_password_token,
          password: values.password,
          password_confirmation: values.password_confirmation
        }
      })
    })
    
    if (response.status >= 400) {
      const json = await response.json()
      
      if (typeof(json.errors) === 'object') {
        const keys = Object.keys(json.errors);
        const values = keys.map(function(v) { return json.errors[v]; });
        
        setError(values[0])
      } else {
        setError(json.errors[0])
      }
      
      setSubmitting(false)
      
      return false
    }

    setSubmitting(false)
    
    message.success(i18n.t('devise.passwords.updated_not_active'), 10)
    
    navigate('/user/login');
  }

  return (
    <div className={styles.container}>
      <div className={styles.lang}>
        <LocaleDropdown />
      </div>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.header}>
            <Link to="/">
              <img alt="logo" className={styles.logo} src={logo} />
              <span className={styles.title}>{i18n.t('sitename')}</span>
            </Link>            
          </div>
          <div className={styles.desc}></div>
        </div>

        <div className={styles.main}>
          <Form onFinish={handleSubmit}>
            {error !== null && !submitting && (
              <Alert
                style={{
                  marginBottom: 24,
                }}
                type="error"
                showIcon
                message={error} />
            )}

            <Form.Item name="password" rules={[{ required: true, message: i18n.t('change_password.index.new_password_blank') }]}>
              <Input.Password size="large" prefix={<LockOutlined />} placeholder={i18n.t('activerecord.attributes.user.password')} />
            </Form.Item>
            
            <Form.Item name="password_confirmation" rules={
              [
                { required: true, message: i18n.t('change_password.index.new_password_confirmation_blank') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(i18n.t('change_password.index.new_password_not_match')));
                  },
                })
              ]
            }>
              <Input.Password size="large" prefix={<LockOutlined />} placeholder={i18n.t('activerecord.attributes.user.password_confirmation')} />
            </Form.Item>

            <Form.Item>
              <Button size="large" type="primary" htmlType="submit" loading={submitting} block>
                {i18n.t('change_password.index.change_password')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ChangePasswordPage;
