import React from 'react';
import { Avatar } from 'antd';
import { first, last } from 'lodash';

const UserAvatar = ({ user, ...avatarProps }) => {
  const name = `${user.first_name} ${user.last_name}` || ''

  const color = (s = 60, l = 80) => {
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = hash % 360;
    return 'hsl('+h+', '+s+'%, '+l+'%)';
  }

  const initials = () => {
    const names = name.split(' ')

    return names.length === 1 ? first(first(names)) : `${first(first(names))}${first(last(names))}`
  }
  
  if (user.avatar === undefined || user.avatar === null) {
    return (
      <Avatar
        alt="avatar"
        {...avatarProps}
        style={{
          backgroundColor: color(),
          verticalAlign: 'middle',
        }}
      >
        {initials()}
      </Avatar>
    )
  }

  return (
    <Avatar
      {...avatarProps}
      src={user.avatar}
      alt="avatar"
    />
  )
}

export default UserAvatar
