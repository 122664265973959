import React, { useState, useContext } from 'react'
import { Dropdown, Menu } from 'antd'
import Locales from 'components/locales'

import I18nContext from 'contexts/i18n-context'

const LocaleDropdown = (props) => {
  const i18n = useContext(I18nContext)
  
  const [selectedLang, setSelectedLang] = useState(() => i18n.locale)
  
  const inlineStyle = {
    cursor: "pointer",
    padding: "12px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 18,
    verticalAlign: "middle"
  }
  
  const menuItemStyle = { minWidth: "160px" }
  const menuItemIconStyle = { marginRight: "8px" }

  const changeLang = ({ key }) => {
    i18n.locale = key
    setSelectedLang(key)
  }
  
  const items = Object.values(Locales).map((localeObj) => ({
    key: localeObj.lang || localeObj.key,
    style: menuItemStyle,
    label: (
      <>
        <span role="img" aria-label={i18n.t(localeObj.title)} style={menuItemIconStyle}>
          {localeObj?.icon || '🌐'}
        </span>
        {i18n.t(localeObj.title)}
      </>
    )
  }))
  
  // const langMenu = {
  //   selectedKeys: [selectedLang],
  //   onClick: changeLang,
  //   items: items
  // }

  return (
    <Dropdown menu={{ selectedKeys: [selectedLang], onClick: changeLang, items }} placement="bottomRight" { ...props }>
      <span style={inlineStyle}>
        <i className="anticon" title={i18n.t(Locales[selectedLang].title)}>
          {Locales[selectedLang].icon}
        </i>
      </span>
    </Dropdown>
  )
}

export default LocaleDropdown