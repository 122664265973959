import { Spin } from 'antd'
import React from 'react'
import { useQuery } from 'jsonapi-react'

const AreaName = (props) => {
  const { id } = props
  const { data, isLoading } = useQuery(id && [
    'areas', id,
    {
      fields: {
        areas: 'name'
      }
    }
  ])
  
  return (
    data && data.name || <Spin size={'small'} />
  )
}

export default AreaName