import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Form, Input, Button, message } from 'antd';

import { UserOutlined } from '@ant-design/icons';

import logo from 'assets/logo.svg';

import styles from 'pages/user/login/style.module.less';

import Footer from 'components/rails-footer';
import I18nContext from 'contexts/i18n-context'

import LocaleDropdown from 'components/locale-dropdown'

function ResetPasswordPage() {
  let navigate = useNavigate();

  const i18n = useContext(I18nContext)

  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (values) => {
    setSubmitting(true);

    await fetch(`${process.env.REACT_APP_API_HOST}/users/password`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Locale': i18n.locale
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        user: {
          email: values.email
        }
      })
    })
    
    setSubmitting(false);
    
    message.success(i18n.t('reset_password.index.success'), 10)
    
    navigate('/user/login');
  }

  return (
    <div className={styles.container}>
      <div className={styles.lang}>
        <LocaleDropdown />
      </div>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.header}>
            <Link to="/">
              <img alt="logo" className={styles.logo} src={logo} />
              <span className={styles.title}>{i18n.t('sitename')}</span>
            </Link>            
          </div>
          <div className={styles.desc}></div>
        </div>

        <div className={styles.main}>
          <Form onFinish={handleSubmit}>
            <Form.Item name="email" rules={[{ required: true, message: i18n.t('login.index.email_blank') }]}>
              <Input size="large" prefix={<UserOutlined />} placeholder={i18n.t('activerecord.attributes.user.email')} />
            </Form.Item>
            
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit" loading={submitting} block>
                {i18n.t('reset_password.index.reset_password')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetPasswordPage;
