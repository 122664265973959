const Locales = {
  'cs': {
    icon: '🇨🇿',
    title: 'locales.cs',
    lang: 'cs'
  },
  'en': {
    icon: '🇬🇧',
    title: 'locales.en',
    lang: 'en'
  }
}

export default Locales