import React from 'react';

import Tabs from './tabs';

const UserSettings = (props) => {
  return (
    <Tabs />
  )
}

export default UserSettings
