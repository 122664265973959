import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Menu, Spin, Dropdown, message } from 'antd'
import React, { useContext } from 'react'

import UserAvatar from '../user-avatar'
import { Link, useNavigate } from 'react-router-dom'
import styles from './index.less'

import { AuthContext } from 'contexts/auth-context'

import I18nContext from 'contexts/i18n-context'

const AvatarDropdown = (props) => {
  const auth = useContext(AuthContext)
  let navigate = useNavigate()

  const i18n = useContext(I18nContext)

  const onMenuClick = (event) => {}

  const { currentUser = { avatar: '', name: '' }, menu } = props

  const items = [
    {
      label: (
        <Link to={'/user/settings'} className={'action'}>
          <SettingOutlined />
          <span style={{ paddingLeft: '6px' }}>{i18n.t('users.index.settings')}</span>
        </Link>
      ),
      key: 'settings',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <a
          href="#"
          onClick={() => {
            auth.signout(() => {
              message.success(i18n.t('devise.sessions.signed_out'))
              navigate('/user/login')
            })
          }}
        >
          <LogoutOutlined />
          <span style={{ paddingLeft: '6px' }}>{i18n.t('users.index.sign_out')}</span>
        </a>
      ),
      key: 'logout',
    },
  ]

  return currentUser && currentUser.last_name ? (
    <Dropdown menu={{ items }}>
      <span style={{ marginLeft: '10px' }} className={`${styles.action} ${styles.account}`}>
        <UserAvatar className={styles.avatar} user={currentUser} size="small" />
        {/* currentUser.avatar !== null ?
              <Avatar size="small" className={styles.avatar} src={currentUser.avatar} alt="avatar" /> : <UserAvatar name={currentUser.name} size='small' />
          */}
        {/*<Avatar size="small" icon={<UserOutlined />} className={styles.avatar} src={currentUser.avatar} alt="avatar" />*/}
        <span style={{ paddingLeft: '5px' }} className={styles.name}>{`${currentUser.first_name} ${currentUser.last_name}`}</span>
      </span>
    </Dropdown>
  ) : (
    <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
  )
}

export default AvatarDropdown
