import React, { createContext, useState, useEffect, useMemo, useContext } from "react";

import I18nContext from 'contexts/i18n-context'

const AuthContext = createContext()

const AuthContextProvider = ({ children }) => {
  const i18n = useContext(I18nContext)
  
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [area, setArea] = useState(null)
  const [timeDiff, setTimeDiff] = useState(0)
  
  const currentTime = () => {
    return Date.now() - timeDiff
  }
  
  const getServerTime = async () => {
    setLoading(true)
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/server_time`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'Locale': i18n.locale
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
    
    if (response.status === 200) {
      const json = await response.json()
      const currentTime = Date.now()
      const serverTime = Date.parse(json['data']['server_time'])
      const diff = currentTime - serverTime
      
      console.log('server time offset', diff, 'ms')
      
      setTimeDiff(diff)
    }
  }

  let reloadUser = async (success) => {
    setLoading(true)
    const sessionToken = sessionStorage.getItem('token')
    
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/users/current`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionToken,
        'Locale': i18n.locale
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
    
    if (response.status === 200) {
      const json = await response.json()
      
      setUser(json.data)
      if (json.data.locale != i18n.locale) {
        i18n.locale = json.data.locale
      }
      setToken(sessionToken)
      success()
      
      return true
    } else {
      sessionStorage.setItem('token', null)
      setUser(null)
    }
    setLoading(false)
    
    return false
  }

  let signinToken = async (success) => {
    setUser(null)
    
    return reloadUser(success)
  }

  let signin = async (email, password, success, failure) => {
    setLoading(true)
    setUser(null)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/users/sign_in`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Locale': i18n.locale
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          user: {
            email: email,
            password: password
          }
        })
      })
      
      const json = await response.json()
      
      if (response.status >= 400) {
        if (json.errors && json.errors[0]) {
          failure(json.errors[0])
        } else {
          failure(i18n.t('network_error'))
        }
        
        return false
      }
      
      const newToken = response.headers.get('authorization')
      sessionStorage.setItem('token', newToken)

      setUser(json.data)
      if (json.data.locale != i18n.locale) {
        i18n.locale = json.data.locale
      }
      setToken(newToken)
      setLoading(false)
      success()
    } catch (error) {
      setLoading(false)
      failure(i18n.t('network_error'))
    }
    
    return true
  }

  let signout = async (success) => {
    setLoading(true)
    await fetch(`${process.env.REACT_APP_API_HOST}/users/sign_out`, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'Locale': i18n.locale
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
    
    setUser(null)
    success()
    
    return true
  };
  
  let forceSignOut = async (success) => {
    setUser(null)
    success()
    
    return true
  };
  
  useEffect(() => {
    const setServerTime = async () => {
      getServerTime()
    }
    
    if (user != null) {
      setServerTime()
    }
  }, [user])
  
  const userIsAdmin = useMemo(() => {
    return user?.role_ids?.includes(1) || false
  }, [user])
  
  const userIsManager = useMemo(() => {
    return user?.role_ids?.includes(3) || false
  }, [user])
  
  const userIsLoggedIn = useMemo(() => {
    if (user !== null) {
      return true
    } else {
      return false
    }
  }, [user])
  
  let value = { user, token, reloadUser, signin, signinToken, signout, forceSignOut, area, setArea, currentTime, loading, userIsAdmin, userIsManager, userIsLoggedIn }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

export { AuthContext, AuthContextProvider };
