import React from 'react'
import { DefaultFooter } from '@ant-design/pro-components'

const RailsFooter = (props) => {
  const copyrightText = () => `Railsformers s.r.o. ${[2022, (new Date().getFullYear() != 2022 && new Date().getFullYear()) || null].filter(Boolean).join('-')}`

  return <DefaultFooter style={{ backgroundColor: 'transparent' }} links={[{ key: 'rails', title: <strong>ManDown</strong>, href: 'https://www.railsformers.com' }]} copyright={copyrightText()}></DefaultFooter>
}

export default RailsFooter
