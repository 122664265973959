import React, { useEffect, useState, useContext, lazy } from 'react'
import { Routes as Router, Route, Navigate, useNavigate } from 'react-router-dom'
import I18nContext from 'contexts/i18n-context'
import { message } from 'antd'

import { AuthContext } from 'contexts/auth-context'
import RequireAuth from 'require-auth'
import RequireAdmin from 'require-admin'

import NotFoundPage from 'pages/not-found'
import LoginPage from 'pages/user/login'
import ResetPasswordPage from 'pages/user/reset-password'
import ChangePasswordPage from 'pages/user/change-password'
import UserSettingsPage from 'pages/user/settings'

const DashboardPage = lazy(() => import('pages/dashboard'))
const EventsPage = lazy(() => import('pages/events'))
const EventsReplayPage  = lazy(() => import('pages/events_replay'))

const AdminAreasPage = lazy(() => import('pages/admin/areas'))
const AdminMobileDevicesPage = lazy(() => import('pages/admin/mobile_devices'))
const AdminBluetoothDevicesPage = lazy(() => import('pages/admin/bluetooth-devices'))
const AdminUnassignedMobileDevicesPage = lazy(() => import('pages/admin/unassigned_mobile_devices'))
const AdminUsersPage = lazy(() => import('pages/admin/users'))
const AdminProjectsPage = lazy(() => import('pages/admin/projects'))
const AdminConfigurationsPage = lazy(() => import('pages/admin/configurations'))
const AdminMapsPage = lazy(() => import('pages/admin/maps'))
const AdminEmailsPage = lazy(() => import('pages/admin/emails'))
const AdminNfcsPage = lazy(() => import('pages/admin/nfcs'))

const Routes = (props) => {  
  let auth = useContext(AuthContext);
  let navigate = useNavigate();
  const i18n = useContext(I18nContext)
  
  const [version, setVersion] = useState(0)
  
  useEffect(() => {
    if (!auth.user) {
      auth.signinToken(() => {
        message.success(i18n.t('devise.sessions.signed_in'));
        navigate('/', { replace: true });
      })
    }
  }, [])
  
  useEffect(() => {
    const unsubscribe = i18n.onChange(() => {
      setVersion(new Date)
    })
  
    return unsubscribe
  }, [])
  
  return (
    <Router>
      <Route path="/user/login" element={<LoginPage />} />
      <Route path="/user/reset-password" element={<ResetPasswordPage />} />
      <Route path="/user/change-password/:reset_password_token" element={<ChangePasswordPage />} />
      <Route path="/" element={<RequireAuth />}>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/user/settings" element={<UserSettingsPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/events-replay" element={<EventsReplayPage />} />
        <Route path="/admin" element={<RequireAdmin />}>
          <Route path="/admin/areas" element={<AdminAreasPage />} />
          <Route path="/admin/areas/:areaId/maps" element={<AdminMapsPage />} />
          <Route path="/admin/areas/:areaId/bluetooth-devices" element={<AdminBluetoothDevicesPage />} />
          <Route path="/admin/areas/:areaId/nfcs" element={<AdminNfcsPage />} />
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/admin/projects" element={<AdminProjectsPage />} />
          <Route path="/admin/mobile-devices" element={<AdminMobileDevicesPage />} />
          <Route path="/admin/unassigned-mobile-devices" element={<AdminUnassignedMobileDevicesPage />} />
          <Route path="/admin/configurations" element={<AdminConfigurationsPage />} />
          <Route path="/admin/emails" element={<AdminEmailsPage />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Router>
  )
}

export default Routes