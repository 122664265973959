import React, { useContext } from 'react'
import { Result, Button } from 'antd';
import I18nContext from 'contexts/i18n-context'
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const i18n = useContext(I18nContext)
  const navigate = useNavigate();
  
  return (
    <Result
      status="404"
      title={i18n.t('errors.not_found.subtitle')}
      subTitle={i18n.t('errors.not_found.something_went_wrong')}
      extra={<Button type="primary" onClick={() => navigate(-1)}>{i18n.t('back')}</Button>}
    />
  )
}

export default NotFound;